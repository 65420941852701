<template>
    <div style="display: flex; flex-direction: column; gap: 16px;">
        <div class="mainContainerPackageName">
            <div style="" class="commonFlexBox">
                <span style="color: #828282; font-weight: 400; font-size: 14px;">{{$t("Customer.PackageAppointments.Packages")}}:</span>
                <span style="font-weight: 500; font-size: 14px;">{{ isEngLanguage() ?  item.packageNameEN: item.packageNameMM }}</span>
            </div>
            <div style="display: flex; justify-content: center; align-items: center; gap: 10px;" v-if="viewTestDetailsModel" @click.stop="toggleSlot">
                    <div style="font-size: 13px; font-weight: 400; color: #1467bf;">{{$t("Customer.Appointments.test_details")}}</div>
                    <img src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg" alt="" style="width: 10px; height: 10px; rotate: -90deg;">
            </div>
            <div style="display: flex; justify-content: center; align-items: center; gap: 10px;" v-if="!viewTestDetailsModel" @click.stop="toggleSlot">
                <div style="font-size: 13px; font-weight: 400; color: #1467bf;">{{$t("Customer.Appointments.test_details")}}</div>
                <img src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg" alt="" style="width: 10px; height: 10px; rotate: 90deg;">
            </div>
        </div>
        <div class="mainContainerApplicableFor">
            <div  class="commonFlexBox" v-if="showApplicableFor"  style="max-width: 70%;">
                <span style="color: #828282; font-weight: 400; font-size: 14px;">{{$t("Customer.PackageAppointments.Applicable_for")}}:</span>
                <div style="flex-wrap: wrap; display: flex;">
                    <span style="font-weight: 500; font-size: 14px;" v-for="(applicable, index) in item.applicableFor" :key="index">{{ applicable }}{{  index != item.applicableFor.length -1 ? ',': ''  }}</span>
                </div>
            </div>
            <div style="" class="commonFlexBox" v-if="showPrice">
                <span style="color: #828282; font-weight: 400; font-size: 14px;" >{{$t("Customer.PackageAppointments.Price")}}:</span>
                <span style="font-weight: 500; color: #21a157;" v-if="!item.sold_out">MMK {{ item.price }} </span>
                <span style="font-weight: 500; color: #EB5757;" v-else>{{$t("Customer.PackageAppointments.Sold_out")}}</span>
            </div>
        </div>
        <v-bottom-sheet  :persistent="true" v-model="viewTestDetailsModel" max-width="425px">
            <v-sheet
                height="410px"
            >
                <div>
                    <div class="topBar"> 
                        <span>More Details</span>
                        <img src="https://s3iconimages.mymedicine.com.mm//closeCircular.svg" alt="" @click="toggleSlot" style="cursor: pointer;">
                    </div>
                    <div class="testCount">
                        <OPDPackageCommonPackageNameAndTestCount :showTestCount="true" :item="item"/>
                    </div>
                    <div class="testList">
                        <div style="display: flex; justify-content: space-between; " v-for="(test, index) in item.testList" :key="index">
                            <div style="display: flex; align-items: center; gap: 4px;">
                                <img src="https://s3iconimages.mymedicine.com.mm/tickBlue.svg" alt="">
                                <span style="font-weight: 400;">{{ test }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="bookPackage" v-if="showBookSlot">
                        <div style="display: flex; align-items: center; justify-content: space-between;">
                            <span>MMK {{ item.price }}</span>
                            <v-btn color="#48acef" style="color: white;" @click="goToPackageDetails" v-if="!item.sold_out"> 
                                {{$t("Customer.PackageAppointments.Book_slot")}}
                            </v-btn>
                            <v-btn color="#48acef" style="color: white;" @click="goToPackageDetails" v-else :disabled="true">  
                                {{$t("Customer.PackageAppointments.Sold_out")}}
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-sheet>
        </v-bottom-sheet>
    </div>

</template>

<script>
import { axios_auth_instance_customer, axios_auth_instance_hba_customer } from '../../../../utils/axios_utils';
import OPDPackageCommonPackageNameAndTestCount from './OPDPackageCommonPackageNameAndTestCount.vue';
// import doctorInfoComponent from '../bookingSuccessPage/doctorInfoComponent.vue';
// import ViewDoctorComponent from '../commonAcrossPages/viewDoctorComponent.vue';

export default {
  components: { OPDPackageCommonPackageNameAndTestCount },
//   components: { doctorInfoComponent, ViewDoctorComponent },
    name: 'OPDPackageNameAndTestDetails',
    props: ["showApplicableFor", "showPrice", "showBookSlot", "item"], 
    data: function() {
        return {
            showTestDetails: false,
            viewTestDetailsModel: false,
            // dependent_members: []
        };
    },
    async mounted() {
        this.allowedDates = this.getXDaysFromToday(90);
    },
    computed: {
        hospitalData () {
            return this.$store.state.particularHospitalState.hospitalInfo;        
        },
    },
    methods: {
        isEngLanguage() {
            try {
                return this.$i18n.locale=='en' ;
            } catch (error) {
                return true;
            }
        },
        async openDoctorBox() {

            try {
                if (this.doctorData == null) {
                    let apiResponse = await axios_auth_instance_hba_customer(`/opd/doctorInfo/${this.item.doctorInfo.id}`);
                    this.doctorData = apiResponse.data.doctorDetails;
                }
                this.viewDoctorModel = true;

            } catch (error) {
                console.log(error);
            }
        },
        toggleViewDoctorDialog(value) {
            if(value) {
                this.viewDoctorModel = true;
            } else {
                this.viewDoctorModel = false;
            }
        },
        formatDate(dateString) {
        // Extract year, month, and day from the given string
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6) - 1; // Months are zero-based in JavaScript
        const day = dateString.substring(6, 8);

        // Create a new Date object
        const date = new Date(year, month, day);

        // Get the day and month name
        const dayName = date.toLocaleString('en-US', { weekday: 'long' });
        const monthName = date.toLocaleString('en-US', { month: 'long' });

        // Format the date
        const formattedDate = `${day} ${monthName}`;

        return formattedDate;
        },
        goToPackageDetails() {
            this.$router.push({
                name: 'OPDPackageDetails',
                params: {
                    'packageID': this.item.id
                }
            })
        },
    openReviewBox() {
        console.dir(this.selectedDateItem);
        console.log(this.selectedID);
        this.bookingInfo = {
            'doctorInfo': {
                doctorName: this.item.doctorInfo.doctorNameEN,
                doctorLogoURL: this.item.doctorInfo.doctorLogo,
                doctorSpecialty: this.item.doctorInfo.doctorSpecialty,
                hospitalLogo: this.hospitalData.hospitalLogo,
                hospitalName: this.hospitalData.name,
                hospitalAddress: this.hospitalData.address,
                slotTime: this.timeInfo,
                slotDate: '24 Aug'                
            }
        }
        this.reviewDialg = true;
        console.dir(this.bookingInfo);
    },
    isDateAllowed(date) {
      console.log(date);
      return this.allowedDates.includes(date);
    },
    getXDaysFromToday(x) {
        const dates = [];
        const today = new Date();
        for (let i = 0; i < x; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            const yyyy = date.getFullYear();
            const mm = String(date.getMonth() + 1).padStart(2, '0');
            const dd = String(date.getDate()).padStart(2, '0');
            dates.push(`${yyyy}-${mm}-${dd}`);
        }
        return dates;
    },
  calculateDateTime(hours, dateNumber, startTime) {
    console.log(hours, dateNumber, startTime);
    const today = new Date();
    hours = Number(hours);
    today.setHours(today.getHours() + hours);
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const dd = String(today.getDate()).padStart(2, '0');
    const HH = String(today.getHours()).padStart(2, '0');
    const mmFormat = String(today.getMinutes()).padStart(2, '0');
    const dateObject = {
        dateValue: Number(`${yyyy}${mm}${dd}`),
        time: `${HH}:${mmFormat}`
    };
    // Check if dateNumber and startTime are greater than dateObject values
    const inputDateValue = Number(dateNumber);
    const inputTimeValue = Number(startTime.replace(':', ''));
    let allowed = inputDateValue > dateObject.dateValue ||
            (inputDateValue === dateObject.dateValue && inputTimeValue > Number(dateObject.time.replace(':', '')));
            console.log(allowed);
    return allowed;
    },

        async bookSlot() {
            this.bookingLoader = true;
            try {
                let apiResponse = await axios_auth_instance_hba_customer.post('/opd/book', {
                    hospital_ID: this.item.doctorInfo.hospitalRegNo,
                    slot_ID: `${this.item.doctorInfo.id}#${this.selectedSlotId}`,
                    booked_for: this.dependentSelected
                });
                let bookingID = apiResponse.data.bookingID;
                this.$router.push({
                    name: 'hospitalBookingSuccess',
                    params: {
                        bookingID: bookingID,
                    }
                });
            } catch (error) {
                console.log(error);
            } finally {
                this.bookingLoader = false;
            }
        },
        async toggleSlot() {
            this.viewTestDetailsModel = !this.viewTestDetailsModel;
            // this.selectedID = null;
            // this.selectedDateItem = null;
            // this.selectedSlotId = null;
            // this.showAddPatient = false;
            // this.showSelectSlot = false;
            // if (this.showTestDetails === false) {
            //     try {
            //         this.slotLoader = true;
            //         let apiResponse = await axios_auth_instance_hba_customer.get(`/opd/slotInfo/${this.item.doctorInfo.hospitalRegNo}/${this.item.doctorInfo.id}`);
            //         this.slotInfo = apiResponse.data.slotList;
            //         this.cutOffHours = apiResponse.data.cutOffHour;
                
            //         this.slotInfo.push({
            //                 slotID: 'CALENDAR',   
            //             }
            //         );
            //         this.showTestDetails = true;
            //     } catch (error) {
            //         console.log(error);
            //     } finally {
            //         this.slotLoader = false;
            //     }
            // } else {
            //     this.showTestDetails = false;
            // }

        },
        callme() {
            this.bookingAllowed = true;
        },
        async getParticularDateData() {
            try {
                this.calendarLoader = true;
                this.overlay = true;
                let splitedDate = this.datePickerModel.split('-');
                let dateInput = splitedDate[0] + splitedDate[1] + splitedDate[2];
                let apiResponse = await axios_auth_instance_hba_customer.get(`/opd/slotInfo/${this.item.doctorInfo.hospitalRegNo}/${this.item.doctorInfo.id}/${dateInput}`);
                if(apiResponse.data.slotList.length == 0) {
                    alert('No slots available');
                    return;
                }
                this.selectedDateItem = {
                    slotID: 'CALENDAR',
                    slotTimes: apiResponse.data.slotList
                }
                this.calendarDateNumber = dateInput;
                this.showSelectSlot = true;
                this.openCalender = false;
            } catch (error) {
                console.log(error);
            } finally {
                this.calendarLoader = false;
            }

        },  
        getClassForDateBox(index, slotItem) {
            // if(slotItem.slotID == 'CALENDAR' && index != this.selectedID) return "dateBox"

            if(slotItem.slotID != 'CALENDAR' && slotItem.slotTimes.length == 0) {
                return "dateBox disabledDateBox"
            }
            if(index == this.selectedID) {
                return "dateBox selectedDateBox"
            } else {
                return "dateBox"
            }
        },
        getClassForSlotBox(index) {
            if(index == this.selectedSlotId) {
                return "slotBox selectedDateBox"
            } else {
                return "slotBox"
            }
        },
        showSelectSlotFn(id, slotItem) {
            this.selectedID = null;
            this.selectedDateItem = null;
            this.selectedSlotId = null;
            this.showAddPatient = false;
            this.showSelectSlot = false;
            // if(id == 'CALENDAR') return;
            if(id == 'CALENDAR') {
                this.selectedID = id;
                this.selectedDateItem = slotItem;
                this.openCalender = true;
                return;
            }
            if(slotItem.slotTimes.length == 0) return;
            this.selectedID = id;
            this.selectedDateItem = slotItem;
            this.showSelectSlot = true;
        },
        showAddPatientFn(id, item) {
            console.log(this.selectedDateItem);
            if(this.calculateDateTime(this.cutOffHours, this.selectedDateItem.slotID == 'CALENDAR' ? this.calendarDateNumber : this.selectedDateItem.slotID, item.startTime) == false) {
                alert('Booking not allowed');
                return;
            }
            // if any selected before
            this.selectedSlotId = null;
            this.timeInfo = null;
            this.showAddPatient = true;
            this.selectedSlotId = id;
            this.timeInfo = item.timeInfo;

        },
        goToHospitalPage() {
            this.$router.push({
            name: "ViewHospital",
            });
        },
    },
};

</script>
<style  scoped>

.mainContainerPackageName {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* background: red;  */
    align-items: center;
}
.mainContainerApplicableFor {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* background: red;  */
    /* align-items: flex-start; */
}
.commonFlexBox {
    display: flex; flex-direction: column; gap: 4px; align-items: flex-start; 
}
.topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8e8e8;
    position: sticky;
    width: 100%;
    max-width: 425px;
}
.testCount {
    width: 100%;
    position: relative;
    padding: 14px 16px 14px 16px;
    border-bottom: 1px solid #e8e8e8;
}
.testList {
    padding: 20px 16px 20px 16px;
    color: #4f4f4f;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 220px;
    overflow-y: scroll;
}
.bookPackage {
    padding: 14px 16px 14px 16px;
    border-top: 1px solid #e8e8e8;
    position: sticky;
}
.v-sheet {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
</style>